/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */

/* Component imports ----------------------------------- */
import ImageHoverContainer from './ImageHoverContainer'
import VignetteRenderer from './VignetteRenderer'

/* Type imports ---------------------------------------- */
import type { PieceJointe } from 'API/__generated__/Api'

/* Internal variables ---------------------------------- */

/* Styled components ----------------------------------- */
const FileName = styled.div`
  font-size: .8rem;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align-last: center;
`

/* Component declaration ------------------------------- */
interface VignetteRendererWithFilenameProps {
  document: PieceJointe;
  onClick: (document: PieceJointe) => void;
  width: string;
  height: string;
}

const VignetteRendererWithFilename: React.FC<VignetteRendererWithFilenameProps> = ({
  document,
  onClick,
  width,
  height,
}) => {

  return (
    <ImageHoverContainer onClick={() => onClick(document)}>
      <VignetteRenderer
        document={document}
        height={height}
        width={width}
      />
      <FileName>
        {document.libelle}
      </FileName>
    </ImageHoverContainer>
  )
}

export default VignetteRendererWithFilename
