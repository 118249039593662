/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useState,
} from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import {
  useLocation,
  useNavigate,
} from 'react-router-dom'

/* Component imports ----------------------------------- */
import {
  Badge,
  Tab,
  Tabs,
} from '@mui/material'
import RouteTitle from 'router/RouteTitle'

/* Internal variables ---------------------------------- */
const tabs = [
  {
    label: 'Mon dossier',
    path: 'mon-dossier',
  },
  {
    label: 'Messagerie',
    path: 'messagerie',
  },
]

/* Styled components ----------------------------------- */
const TabsContainer = styled(Tabs)`
  @media ${(props) => props.theme.media.desktop} {
    button {
      padding-bottom: 0px;
    }
  }

  @media ${(props) => props.theme.media.tablet}, ${(props) => props.theme.media.mobile.main} {
    button {
      padding-bottom: 0px !important;
      padding-top: 2px !important;
    }
  }
`

const LabelContainer = styled.div`
  padding: 3px 13px 5px 0px;

  @media ${(props) => props.theme.media.tablet} {
    padding: 3px 20px 5px 0px;
  }

  @media ${(props) => props.theme.media.desktop} {
    padding: 10px 10px 10px 0px;
  }
`

/* Component declaration ------------------------------- */
interface MainLayoutNavTabsProps {
  mobile?: boolean;
  tabValue: number | boolean;
  setTabValue: (value: number | boolean) => void;
  messagesLength: number;
}

const MainLayoutNavTabs: React.FC<MainLayoutNavTabsProps> = ({
  mobile = false,
  tabValue,
  setTabValue,
  messagesLength,
}) => {
  const location = useLocation()
  const navigate = useNavigate()
  const [ title, setTitle ] = useState<string>('')

  useEffect(() => {
    const newTabValue = tabs.findIndex((tab) => location.pathname.indexOf(tab.path) !== -1)
    setTabValue(newTabValue)
    if (tabs[newTabValue]?.label) {
      setTitle(tabs[newTabValue].label)
    }
  }, [ location.pathname ])

  const handleTabChange = (newValue: number): void => {
    setTabValue(newValue)
    if (newValue !== -1) {
      navigate(`/${tabs[newValue].path}/`)
    }
  }

  const label = (tab: string) => (tab === 'Messagerie' && messagesLength > 0) ?
    <Badge
      badgeContent={messagesLength}
      invisible={messagesLength <= 0}
      color="error"
      anchorOrigin={
        {
          vertical: 'top',
          horizontal: 'right',
        }
      }
    >
      <LabelContainer>
        {tab}
      </LabelContainer>
    </Badge> :
    tab

  return (
    <>
      <RouteTitle title={title} />
      {
        !mobile ?
          <TabsContainer value={tabValue === -1 ? false : tabValue}>
            {
              tabs.map((tab, index) => (
                <Tab
                  key={`${tab.path}-${index}`}
                  onClick={() => handleTabChange(index)}
                  label={label(tab.label)}
                />
              ))
            }
          </TabsContainer> :
          <TabsContainer
            value={(tabValue as number) + 1}
            variant="fullWidth"
          >
            {
              [ 'Infos dossier', 'Suivi dossier', 'Messagerie' ].map((tab, index) => (
                <Tab
                  key={`${tab}-${index}`}
                  onClick={() => handleTabChange(index - 1)}
                  label={label(tab)}
                />
              ))
            }
          </TabsContainer>
      }
    </>
  )
}

export default MainLayoutNavTabs
