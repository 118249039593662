/* Module imports ----------------------------------------------------------- */
import { saveAs } from 'file-saver'
import { isValidString } from './isValidString'

export const getCleanFilename = (libelleWithPotentialExtension: string, fileName: string) => {
  const extension = fileName.split('.')[fileName.split('.').length - 1]
  const libelle = libelleWithPotentialExtension.split('.')[0]

  return `${libelle}.${extension}`
}

export const downloadFile = (fileName: string, url?: string | null) => {
  if (!isValidString(url)) {
    return
  }
  fetch(url ?? '')
    .then((r) => {
      if (r.status === 200) {
        return r.blob()
      }
      throw Promise.reject(new Error(r.statusText))
    })
    .then((blob) => saveAs(blob, fileName))
    .catch(console.error)
}

export const printFile = (url?: string | null) => {
  if (!isValidString(url)) {
    return
  }

  fetch(url ?? '')
    .then((r) => {
      if (r.status === 200) {
        return r.blob()
      }
      throw Promise.reject(new Error(r.statusText))
    })
    .then((blob) => {
      const blobUrl = URL.createObjectURL(blob)
      const printWindow = window.open(blobUrl, '_blank')

      if (printWindow) {
        printWindow.onload = () => {
          printWindow.print()
        }
      } else {
        console.error('Failed to open print window')
      }
    })
    .catch(console.error)
}
