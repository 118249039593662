/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Component imports ----------------------------------- */
import GroupLogo from 'components/GroupLogo/GroupLogo'

/* Styled components ----------------------------------- */
const GroupLogoContainer = styled.div`
  text-align: center;

  .loader-logo {
    width: 100%;
    height: auto;
    max-width: 250px;
    max-height: 100px;

    @media ${(props) => props.theme.media.mobile.portrait} {
      max-width: min(40vh, 40vw);
      max-height: min(20vh, 20vw);
    }

    @media ${(props) => props.theme.media.mobile.main} {
      max-width: min(40vh, 40vw);
      max-height: min(15vh, 15vw);
    }
  }
`

const WelcomeMessage = styled.div`
  font-size: 1.8rem;
  color: ${(props) => props.theme.palette.secondary.main};
  margin-top: 2rem;

  @media ${(props) => props.theme.media.mobile.main} {
    font-size: 1.2rem;
  }

  @media ${(props) => props.theme.media.mobile.main} {
    margin-top: 10px;
  }
`

/* Component declaration ------------------------------- */
interface AuthWelcomeMessageProps {
  children: React.ReactNode;
}

const AuthWelcomeMessage: React.FC<AuthWelcomeMessageProps> = ({ children }) => {

  return (
    <GroupLogoContainer>
      <GroupLogo />
      <WelcomeMessage>
        {children}
      </WelcomeMessage>
    </GroupLogoContainer>
  )
}

export default AuthWelcomeMessage
