/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { getCleanFilename } from 'helpers/mediaUtils'

/* Component imports ----------------------------------- */
import { Card } from '@mui/material'
import ColoredSquareChip from 'components/ColoredSquareChip/ColoredSquareChip'
import CaseFollowUpDownloadButton from './CaseFollowUpDownloadButton'
import CaseFollowUpRateButton from './CaseFollowUpRateButton'
// import CaseFollowUpRdvButton from './CaseFollowUpRdvButton'

/* Type imports ---------------------------------------- */
import {
  WorkflowEtat,
  type JalonSimplifie,
} from 'API/__generated__/Api'

/* Types declaration ----------------------------------- */

/* Styled components ----------------------------------- */
const CardContentContainer = styled.div`
  display: grid;
  grid-template-columns: 50% 25% calc(25% - 20px);
  gap: 10px;
  font-size: .9rem;
  padding: 10px;
  min-height: 60px;
  align-items: center;

  @media ${(props) => props.theme.media.mobile.portrait} {
    display: flex;
    flex-direction: column;
  }
`

const TitleContainer = styled.div`
  padding-left: 10px;
`

const Chip = styled(ColoredSquareChip)`
  width: 100%;
  height: 100%;
  min-height: 37px;
`

/* Component declaration ------------------------------- */
interface CaseFollowUpHistoryCardProps {
  history: JalonSimplifie;
  displayRateButton: boolean;
}

const CaseFollowUpHistoryCard: React.FC<CaseFollowUpHistoryCardProps> = ({ history, displayRateButton }) => {
  const getButton = () => {
    if (history.pieceJointes) {
      return (
        <CaseFollowUpDownloadButton
          name={getCleanFilename(history.pieceJointes.libelle ?? '', history.pieceJointes.fileName ?? '')}
          url={history.pieceJointes.url ?? ''}
        />
      )
    }
    if (history.libelle === 'Dossier terminé') {
      if (displayRateButton) {
        return <CaseFollowUpRateButton />
      }
      return <div />
    }
    // if (history.libelle === 'RDV métré' && history.etat === WorkflowEtat.EnAttente) {
    //   return <CaseFollowUpRdvButton />
    // }
    // if (history.libelle === 'RDV Travaux' && history.etat === WorkflowEtat.EnAttente) {
    //   return <CaseFollowUpRdvButton />
    // }
    return <div />
  }

  return (
    <Card>
      <CardContentContainer>
        <TitleContainer>
          {history.libelle}
        </TitleContainer>
        {getButton()}
        {
          history.actionDate &&
            <Chip color={history.etat === WorkflowEtat.Annule ? 'red' : history.etat === WorkflowEtat.Fait ? 'green' : 'grey'}>
              {history.actionDate}
            </Chip>
        }
      </CardContentContainer>
    </Card>
  )
}

export default CaseFollowUpHistoryCard
