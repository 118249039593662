/* Framework imports -------------------------------------------------------- */
import * as RTKQuery from '@reduxjs/toolkit/query/react'

/* Module imports ----------------------------------------------------------- */
import OpenAPI from 'API/OpenAPI'
import { processRequest } from 'helpers/fetchHelpers'
import { apiUrl } from 'helpers/readConfig'

/* Type imports ------------------------------------------------------------- */
import type {
  LoginResponseDossier,
  LoginAuthentificationCreateParams,
  JalonSimplifie,
  Message,
  CodeLabel,
  MessageRequest,
  RepondreMessageRequest,
  NotationAssureDossierCreatePayload,
  NotationAssureDossierCreateParams,
  ValidationProblemDetails,
  Dossier,
  DocumentDossierDetailParams,
  Document,
} from 'API/__generated__/Api'

/* API Redux service -------------------------------------------------------- */
export const api = RTKQuery.createApi(
  {
    reducerPath: 'api',
    baseQuery: RTKQuery.fetchBaseQuery({ baseUrl: apiUrl }) as RTKQuery.BaseQueryFn<string | RTKQuery.FetchArgs, unknown, ValidationProblemDetails>,
    tagTypes: [
      'AUTH',
      'MESSAGES',
      'RATE',
    ],
    endpoints: (build) => {
      return {
        /* Authentification */
        authenticate: build.mutation<LoginResponseDossier, LoginAuthentificationCreateParams>(
          {
            queryFn: async (args, queryApi, extraOptions, baseQuery) => {
              return processRequest(
                OpenAPI.authentification.loginExtranetAssureAuthentificationCreate,
                args,
              )
            },
            invalidatesTags: [ 'AUTH', 'MESSAGES', 'RATE' ],
          },
        ),
        /* Case */
        getCaseInfos: build.query<Dossier, string>(
          {
            queryFn: async (args, queryApi, extraOptions, baseQuery) => {
              return processRequest(
                OpenAPI.dossier.dossierDetail,
                args,
              )
            },
          },
        ),
        getCaseDocumentList: build.query<Document[], DocumentDossierDetailParams>(
          {
            queryFn: async (args, queryApi, extraOptions, baseQuery) => {
              return processRequest(
                OpenAPI.dossier.documentDossierDetail,
                args,
              )
            },
          },
        ),
        getCaseHistoryList: build.query<JalonSimplifie[], string>(
          {
            queryFn: async (args, queryApi, extraOptions, baseQuery) => {
              return processRequest(
                OpenAPI.dossier.workflowDossierDetail,
                args,
              )
            },
          },
        ),
        getCaseRating: build.query<boolean, string>(
          {
            queryFn: async (args, queryApi, extraOptions, baseQuery) => {
              return processRequest(
                OpenAPI.dossier.hasNotationAssureDossierDetail,
                args,
              )
            },
            providesTags: (result, error, arg) => [
              { type: 'RATE', id: result ? 'true' : 'false' },
            ],
          },
        ),
        getCaseRatingQuestionList: build.query<CodeLabel[], void>(
          {
            queryFn: async (args, queryApi, extraOptions, baseQuery) => {
              return processRequest(
                OpenAPI.selectList.getQuestionsExperienceAssureList,
              )
            },
          },
        ),
        postCaseRating: build.mutation<void, {query: NotationAssureDossierCreateParams; data: NotationAssureDossierCreatePayload}>(
          {
            queryFn: async (args, queryApi, extraOptions, baseQuery) => {
              return processRequest(
                OpenAPI.dossier.notationAssureDossierCreate,
                args.query,
                args.data,
              )
            },
            invalidatesTags: [ 'RATE' ],
          },
        ),
        /* Internal Exchanges */
        getMessageList: build.query<Message[], string>(
          {
            queryFn: async (args, queryApi, extraOptions, baseQuery) => {
              return processRequest(
                OpenAPI.dossier.messagesDossierDetail,
                args,
              )
            },
            providesTags: (result = [], error, arg) => [
              { type: 'MESSAGES', id: 'LIST' },
              ...result.map((r) => ({ type: 'MESSAGES' as const, id: `${r.id}` })),
            ],
          },
        ),
        getMessagesRecipientList: build.query<CodeLabel[], string>(
          {
            queryFn: async (args, queryApi, extraOptions, baseQuery) => {
              return processRequest(
                OpenAPI.dossier.destinatairesMessagesDetail,
                args,
              )
            },
          },
        ),
        postNewMessage: build.mutation<void, {caseId: string; data: MessageRequest}>(
          {
            queryFn: async (args, queryApi, extraOptions, baseQuery) => {
              return processRequest(
                OpenAPI.dossier.messagesDossierCreate,
                args.caseId,
                args.data,
              )
            },
            invalidatesTags: [ 'MESSAGES' ],
          },
        ),
        postAnswerMessage: build.mutation<void, {id: string; data: RepondreMessageRequest}>(
          {
            queryFn: async (args, queryApi, extraOptions, baseQuery) => {
              return processRequest(
                OpenAPI.messages.repondreMessagesCreate,
                args.id,
                args.data,
              )
            },
            invalidatesTags: [ 'MESSAGES' ],
          },
        ),
      }
    },
  },
)

/* Export hooks */
export const {
  // Auth
  useAuthenticateMutation,
  // Case
  useGetCaseInfosQuery,
  useLazyGetCaseInfosQuery,
  useGetCaseDocumentListQuery,
  useLazyGetCaseDocumentListQuery,
  useGetCaseHistoryListQuery,
  useLazyGetCaseHistoryListQuery,
  useGetCaseRatingQuery,
  useLazyGetCaseRatingQuery,
  usePostCaseRatingMutation,
  useGetCaseRatingQuestionListQuery,
  useLazyGetCaseRatingQuestionListQuery,
  // Internal Exchanges
  useGetMessageListQuery,
  useLazyGetMessageListQuery,
  useGetMessagesRecipientListQuery,
  useLazyGetMessagesRecipientListQuery,
  usePostNewMessageMutation,
  usePostAnswerMessageMutation,
} = api
