/* Module imports ----------------------------------------------------------- */
import OpenAPI from 'API/OpenAPI'
import { isValidString } from './isValidString'

/* Component imports ----------------------------------- */
import { toast } from 'react-toastify'

/* Config imports -------------------------------------- */
import defaultConfig from 'config/default.config.json'

/* Config declaration ---------------------------------- */
type ConfigStringValue = {
  description: string;
  valeur: string;
}

interface Config {
  api: {
    url: ConfigStringValue;
  };
}

export let apiUrl: string = defaultConfig.api.url.valeur

export async function readConfig() {
  return fetch('/app.config.json')
    .then((response) => response.json())
    .then((reponse) => {
      const config = reponse as Config
      // Api
      if (isValidString(config?.api?.url?.valeur)) {
        OpenAPI.baseUrl = config.api.url.valeur
        apiUrl = config.api.url.valeur
      } else {
        toast.error("L'url de l'api n'a pas été trouvée dans le fichier app.config.json")
      }
    })
    .catch((err) => {
      toast.error('Erreur lors de la lecture du fichier app.config.json. Le fichier semble être invalide.')
      console.error(err)
    })
}
