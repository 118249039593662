/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useState,
} from 'react'
import styled from '@emotion/styled'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import { useCaseId } from 'store/hooks'
import {
  useGetCaseRatingQuestionListQuery,
  usePostCaseRatingMutation,
} from 'store/api'
import { isApiError } from 'helpers/fetchHelpers'

/* Component imports ----------------------------------- */
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { Field } from 'formik'
import { TextField } from 'formik-mui'
import CloseButton from 'components/CloseButton/CloseButton'
import LongButton from 'components/LongButton/LongButton'
import Rating from 'components/Rating/Rating'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'

/* Type imports ---------------------------------------- */
import type {
  FormikContextType,
  FormikHelpers,
} from 'formik'
import type { Shape } from 'components/FormikLogic/FormikLogic'
import type { ExpAssureReponsesRequest } from 'API/__generated__/Api'

/* Type declarations ----------------------------------- */

interface NotationRequest {
  notes: ExpAssureReponsesRequest[];
  commentaire: string;
}

const ratingSchema = Yup.object<Shape<NotationRequest>>({
  notes: Yup.array(Yup.mixed<ExpAssureReponsesRequest>()).required(),
  commentaire: Yup.string().required('Le commentaire est obligatoire'),
}).required()

type RatingForm = FormikContextType<NotationRequest>

/* Internal variables ---------------------------------- */

/* Styled components ----------------------------------- */
const RateButton = styled(Button)`
  height: 100%;

  @media ${(props) => props.theme.media.mobile.main} {
    width: 100%;
  }
`

const DialogTitleContainer = styled(DialogTitle)`
  font-weight: bold;
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: 1.4rem;
  text-transform: uppercase;
  text-align: center;

  @media ${(props) => props.theme.media.mobile.portrait} {
    font-size: 1.1rem;
  }
`

const DialogContentContainer = styled(DialogContent)`
  padding: 30px 50px 0px;

  @media ${(props) => props.theme.media.mobile.portrait} {
    padding: 0px 20px;
  }
`

const DialogActionContainer = styled(DialogActions)`
  justify-content: center;
  margin: 20px;

  @media ${(props) => props.theme.media.mobile.portrait} {
    display: flex;
    flex-direction: column-reverse;
    gap: 10px;
    margin: 10px;

    button {
      width: 100%;
      margin-left: 0px !important;
    }
  }
`

const RateLine = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  gap: 40px;
  align-items: center;

  @media ${(props) => props.theme.media.mobile.portrait} {
    flex-direction: column;
    gap: 0px;
    align-items: start;
  }
`

/* Component declaration ------------------------------- */
interface CaseFollowUpRateButtonProps {}

const CaseFollowUpRateButton: React.FC<CaseFollowUpRateButtonProps> = () => {
  const caseId = useCaseId()
  const [ open, setOpen ] = useState<boolean>(false)

  const {
    currentData: questions = [],
    isFetching: isFetchingQuestions,
  } = useGetCaseRatingQuestionListQuery()
  const [
    submitRating,
  ] = usePostCaseRatingMutation()

  const onSubmit = async (values: NotationRequest, { setSubmitting, resetForm }: FormikHelpers<NotationRequest>): Promise<void> => {
    const response = await submitRating({
      data: values.notes,
      query: { dossier: caseId, commentaire: values.commentaire },
    })

    if (!isApiError(response)) {
      resetForm()
      setOpen(false)
    } else {
      setSubmitting(false)
    }
  }

  const formikForm: RatingForm = useForm<NotationRequest>(
    {
      initialValues: {
        commentaire: '',
        notes: [],
      },
      onSubmit: onSubmit,
      validationSchema: ratingSchema,
    },
  )

  useEffect(() => {
    if (!isFetchingQuestions) {
      formikForm.setFieldValue('notes', questions.map(({ code }) => ({ codeQuestion: code, note: 0 })))
    }
  }, [ isFetchingQuestions ])

  const onClick = () => {
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }

  const handleRateValue = (index: number, value?: number | null): void => {
    formikForm.setFieldValue(`notes[${index}].note`, value)
  }

  return (
    <>
      <RateButton
        variant="contained"
        onClick={onClick}
      >
        Noter votre expérience
      </RateButton>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="md"
      >
        <DialogTitleContainer>
          Noter votre expérience
          <CloseButton handleClose={onClose} />
        </DialogTitleContainer>
        <Form form={formikForm}>
          {
            isFetchingQuestions ?
              <CircularProgress /> :
              <DialogContentContainer>
                {
                  questions.map(({ code, libelle }, index) => (
                    <RateLine key={code}>
                      <div>
                        {libelle}
                      </div>
                      <Rating
                        value={formikForm.values.notes?.[index]?.note ?? 0}
                        onChange={(value) => handleRateValue(index, value)}
                      />
                    </RateLine>
                  ))
                }
                <FormBoldTitle>
                  Commentaire
                </FormBoldTitle>
                <Field
                  component={TextField}
                  placeholder="Votre message"
                  rows={3}
                  multiline
                  name="commentaire"
                />
              </DialogContentContainer>
          }
          <DialogActionContainer>
            <LongButton
              variant="outlined"
              onClick={onClose}
            >
              Annuler
            </LongButton>
            <LongButton
              type="submit"
              variant="contained"
              disabled={formikForm.isSubmitting}
            >
              Valider
            </LongButton>
          </DialogActionContainer>
        </Form>
      </Dialog>
    </>
  )
}

export default CaseFollowUpRateButton
