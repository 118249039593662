/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useState,
} from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import {
  Outlet,
  useLocation,
  useNavigate,
} from 'react-router-dom'
import { useMobileStatusBarStyle } from 'helpers/hooks/useMobileStatusBarStyle'
import {
  onLogoutReset,
  useCaseId,
} from 'store/hooks'
import {
  useGetCaseDocumentListQuery,
  useGetCaseInfosQuery,
  useGetMessageListQuery,
} from 'store/api'
import { useIsDesktop } from 'helpers/hooks/useIsMedia'

/* Component imports ----------------------------------- */
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
} from '@mui/material'
import { LogoutRounded } from '@mui/icons-material'
import BaseLayout from 'layouts/BaseLayout/BaseLayout'
import ErrorBoundaryPage from 'layouts/MainLayout/ErrorBoundaryPage/ErrorBoundaryPage'
import PageContainer from 'layouts/PageContainer/PageContainer'
import GroupLogo from 'components/GroupLogo/GroupLogo'
import CustomIconButton from 'components/IconButtons/CustomIconButton/CustomIconButton'
import MainLayoutNavTabs from './MainLayoutComponents/MainLayoutNavTabs'
import MainLayoutTitleStatus from './MainLayoutComponents/MainLayoutTitleStatus'
import MainLayoutSummary from './MainLayoutComponents/MainLayoutSummary'

/* Type imports ---------------------------------------- */

/* Internal variables ---------------------------------- */

/* Styled components ----------------------------------- */
const DesktopHeader = styled.div`
  display: none;

  @media ${(props) => props.theme.media.desktop} {
    display: initial;
  }
`

const MobileHeader = styled.div`
  div {
    padding-right: 1px;
  }

  @media ${(props) => props.theme.media.desktop} {
    display: none;
  }
`

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: end;
  overflow-x: overlay;
  overflow-y: hidden;
  z-index: 200;

  background-color: #FFFFFF;
  border-bottom: 2px solid ${(props) => props.theme.colors.lightgrey};

  padding: 10px 0px;
  height: 50px;

  button {
    font-size: 1rem;
  }

  @media ${(props) => props.theme.media.desktop} {
    height: 70px;
    // On right side we add an additional 15px because the rest of the outlet
    // layout will have that extra padding for the possible scrollbar width.
    // If we change the scrollbar we may have to change this value
    // The rest of the calcul is to add more padding on large screens, as in PageContainer
    padding: 10px max(calc(15vw - 200px + 15px), calc(0.5rem + 15px)) 0px max(calc(15vw - 200px), 0.5rem);
  }
`

const HeaderTitleContainer = styled.div`
  color: ${(props) => props.theme.palette.secondary.main};
  font-weight: bold;

  display: flex;
  align-items: center;
  font-size: 1.2rem;

  .loader-logo {
    max-width: 140px;
    max-height: 35px;
    margin-right: 15px;
    margin-top: -5px;

    @media ${(props) => props.theme.media.desktop} {
      max-height: 50px;
    }
  }
`

const HeaderTitleButton = HeaderTitleContainer.withComponent(Button)

const HeaderTitleButtonContainer = styled(HeaderTitleButton)`
  height: max-content;
  align-self: center;

  @media ${(props) => props.theme.media.desktop} {
    margin-bottom: 10px;
  }
`

const SearchBar = styled.div`
  display: flex;
  padding-bottom: 15px;
  gap: 10px;
`

const OtherPageContainer = styled.div`
  position: relative;
`

const MainLayoutContainer = styled.div`
  background-color: ${(props) => props.theme.colors.lightgrey};
  flex-grow: 1;
  flex-shrink: 1;

  @media ${(props) => props.theme.media.desktop}, ${(props) => props.theme.media.tablet} {
    display: contents;
  }

  @media ${(props) => props.theme.media.mobile.main} {
    overflow-x: hidden;
    overflow-y: scroll;
  }

  .outlet-scrollable-content {
    overflow-x: hidden;
    flex-grow: 1;
    flex-shrink: 1;
    background-color: ${(props) => props.theme.colors.lightgrey};
    padding: ${(props) => props.theme.layoutPadding.main};

    @media ${(props) => props.theme.media.mobile.main} {
      overflow-y: hidden;
    }

    @media ${(props) => props.theme.media.desktop} {
      // Add padding on sides the larger the screen is
      padding: ${(props) => props.theme.layoutPadding.desktop};
      padding-top: 0px;
    }
  }
`

const CardMobileContainer = styled.div`
  background-color: ${(props) => props.theme.colors.main};
  border-bottom: 2px solid ${(props) => props.theme.colors.lightgrey};
  padding-top: 5px;

  @media ${(props) => props.theme.media.desktop} {
    display: none;
  }
`

const CaseTitleStatusMobileContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  border-bottom: 2px solid ${(props) => props.theme.colors.grey};
  padding: 4px 10px 8px 10px;

  div {
    text-align: center;
  }

  @media ${(props) => props.theme.media.mobile.main} {
    gap: 5px;
  }
`

const OutletDesktopContainer = styled.div`
  display: none;

  @media ${(props) => props.theme.media.desktop} {
    display: flex;
    gap: 30px;
    & > div:nth-of-type(2) {
      width: 100%;
    }
  }
`

const OutletMobileContainer = styled.div`
  display: block;
  padding-top: 10px;

  @media ${(props) => props.theme.media.desktop} {
    display: none;
  }
`

const ResumeCardContainer = styled(Card)`
  display: none;
  width: 30vw !important;

  @media ${(props) => props.theme.media.desktop} {
    display: initial;
    max-height: calc(100vh - 120px);
    overflow-y: auto;
    position: sticky;
    top: 0px;
    font-size: .9rem;
    height: fit-content;
  }
`

const BlankDiv = styled.div`
  @media ${(props) => props.theme.media.desktop} {
    height: 30px;
    background-color: ${(props) => props.theme.colors.lightgrey};
    flex-shrink: 0;
  }
`

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 20px;
`

const BoldSeparator = styled.div`
  width: 200% !important;
  margin: 15px -15px 5px -15px;
  border-bottom: 2px solid ${(props) => props.theme.colors.lightgrey};
`

/* Component declaration ------------------------------- */
interface MainLayoutProps {}

const MainLayout: React.FC<MainLayoutProps> = () => {
  useMobileStatusBarStyle('black')
  const location = useLocation()
  const navigate = useNavigate()
  const isDesktop = useIsDesktop()
  const caseId = useCaseId()
  const [ tabValue, setTabValue ] = useState<number | boolean>(0)

  const { currentData: caseInfos } = useGetCaseInfosQuery(caseId)
  const { currentData: documents = []} = useGetCaseDocumentListQuery({ dossier: caseId, AvecBrouillon: false })
  const { currentData: messages = []} = useGetMessageListQuery(caseId)

  useEffect(() => {
    // The following line is meant to change display style when we display the case layout submenus
    const mainLayoutPageContainer = document.querySelector('.main-layout-page-container')

    if (mainLayoutPageContainer?.querySelector('.case-layout-container')) {
      mainLayoutPageContainer.classList.add('display-contents')
    } else {
      mainLayoutPageContainer?.classList.remove('display-contents')
    }
  }, [ location.pathname ])

  const onLogout = () => {
    onLogoutReset()
  }

  const navigateToMainPage = () => {
    navigate('/')
  }

  return (
    <BaseLayout>
      <ErrorBoundaryPage>
        <DesktopHeader>
          <HeaderContainer>
            <HeaderTitleButtonContainer onClick={navigateToMainPage}>
              <GroupLogo />
              Extranet
            </HeaderTitleButtonContainer>
            <MainLayoutNavTabs
              tabValue={tabValue}
              setTabValue={setTabValue}
              messagesLength={messages.length}
            />
            <HeaderTitleContainer>
              <SearchBar>
                <OtherPageContainer>
                  <CustomIconButton
                    Icon={LogoutRounded}
                    variant="contained"
                    onClick={onLogout}
                  />
                </OtherPageContainer>
              </SearchBar>
            </HeaderTitleContainer>
          </HeaderContainer>
        </DesktopHeader>
        <MobileHeader>
          <HeaderContainer>
            <HeaderTitleButtonContainer onClick={navigateToMainPage}>
              <GroupLogo />
              Extranet
            </HeaderTitleButtonContainer>
            <HeaderTitleContainer>
              <OtherPageContainer>
                <CustomIconButton
                  Icon={LogoutRounded}
                  variant="contained"
                  onClick={onLogout}
                />
              </OtherPageContainer>
            </HeaderTitleContainer>
          </HeaderContainer>
        </MobileHeader>
        <PageContainer className="main-layout-page-container">
          <MainLayoutContainer className="case-layout-container">
            <CardMobileContainer>
              <CaseTitleStatusMobileContainer>
                <MainLayoutTitleStatus caseId={caseId} />
              </CaseTitleStatusMobileContainer>
              <MainLayoutNavTabs
                tabValue={tabValue}
                setTabValue={setTabValue}
                messagesLength={messages.length}
                mobile
              />
            </CardMobileContainer>
            <BlankDiv />
            <div className="outlet-scrollable-content">
              {
                isDesktop ?
                  <OutletDesktopContainer>
                    <ResumeCardContainer>
                      <CardContent>
                        <MainLayoutTitleStatus caseId={caseId} />
                        <BoldSeparator />
                        {
                          !caseInfos ?
                            <CircularProgress color="secondary" /> :
                            <MainLayoutSummary
                              summary={caseInfos}
                              documents={documents}
                            />
                        }
                      </CardContent>
                    </ResumeCardContainer>
                    <div>
                      <Outlet />
                    </div>
                  </OutletDesktopContainer> :
                  <OutletMobileContainer>
                    {
                      tabValue === -1 ?
                        (
                          !caseInfos ?
                            <LoaderContainer>
                              <CircularProgress size={60} />
                            </LoaderContainer> :
                            <MainLayoutSummary
                              summary={caseInfos}
                              documents={documents}
                            />
                        ) :
                        (
                          <Outlet />
                        )
                    }
                  </OutletMobileContainer>
              }
            </div>
          </MainLayoutContainer>
        </PageContainer>
      </ErrorBoundaryPage>
    </BaseLayout>
  )
}

export default MainLayout
