/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { isValidString } from 'helpers/isValidString'

/* Component imports ----------------------------------- */
import { Button } from '@mui/material'
import { InsertDriveFileOutlined } from '@mui/icons-material'

/* Type imports ---------------------------------------- */

/* Type declarations ----------------------------------- */

/* Internal variables ---------------------------------- */

/* Styled components ----------------------------------- */
const DownloadButton = styled(Button)`
  font-size: .85rem;
  padding: 5px 7px 5px 3px;
  justify-content: left;
  height: 100%;

  svg {
    margin-right: 5px;
  }

  @media ${(props) => props.theme.media.mobile.main} {
    width: 100%;
  }
`

/* Component declaration ------------------------------- */
interface CaseFollowUpDownloadButtonProps {
  url?: string;
  name: string;
}

const CaseFollowUpDownloadButton: React.FC<CaseFollowUpDownloadButtonProps> = ({
  url,
  name,
}) => {

  const onClick = () => {
    window.open(url ?? '', '_blank', 'noreferrer')
  }

  return (
    <DownloadButton
      variant="outlined"
      onClick={onClick}
      disabled={!isValidString(url)}
    >
      <InsertDriveFileOutlined />
      {name}
    </DownloadButton>
  )
}

export default CaseFollowUpDownloadButton
